<template>
  <div>
    <div class="card p-2 bg-card">
      <div class="align-center-flex">
        <div>
          <input
            v-model="searchResult"
            placeholder="Search"
            class="mr-bottom form-control input-sm flex"
          />
        </div>
        <div>
          <!-- <button
            @click="addUser()"
            class="btn pointer btn-primary btn-sm margin-right"
            :disabled="
              !this.$store.state.auth.permissions.includes('consultant-store')
            "
          >
            + Add
          </button> -->
        </div>
      </div>
    </div>
    <b-card>
      <div class="user-select">
        <div class="card-company-table card card-congratulation-medal">
          <div
            class="mb-0 table-responsive border rounded"
            style="min-height: 40vh"
          >
            <table role="table" class="table table-hover">
              <thead role="rowgroup header">
                <tr role="row">
                  <th role="columnheader">#</th>
                  <th role="columnheader" scope="col">First Name</th>
                  <th role="columnheader" scope="col">Last Name</th>
                  <th role="columnheader" scope="col">Email</th>
                  <th role="columnheader" scope="col">Status</th>
                  <!-- <th role="columnheader" scope="col">Designation</th> -->

                  <th role="columnheader" scope="col" class="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="rowgroup">
                <tr
                  v-for="(user, index) in allUsers"
                  :key="user._id"
                  role="row"
                  class="custom-table-row"
                  @click="UserDetails(user._id, user)"
                >
                  <td role="cell">
                    {{ (currentPage - 1) * perPage + index + 1 }}
                  </td>
                  <td role="cell">{{ user.firstname }}</td>
                  <td role="cell">{{ user.lastname }}</td>
                  <td role="cell">{{ user.email }}</td>
                  <td role="cell">
                    <b-badge
                      v-if="
                        user.is_active &&
                        (!user.failed_login_attempts ||
                          typeof user.failed_login_attempts == 'undefined' ||
                          user.failed_login_attempts == null ||
                          user.failed_login_attempts <= 3) &&
                        (typeof user.is_blocked == 'undefined' ||
                          user.is_blocked == false)
                      "
                      variant="success"
                      >Active</b-badge
                    >

                    <b-badge
                      v-else-if="
                        typeof user.is_blocked != 'undefined' &&
                        user.is_blocked == true
                      "
                      v-b-tooltip.hover.left.v-warning
                      title="Blocked due to Inactivity"
                      variant="warning"
                      ><span class="align-middle"
                        ><feather-icon icon="InfoIcon" class="mr-25" />
                        <span>Temporarily Blocked</span></span
                      >
                    </b-badge>

                    <b-badge
                      v-else-if="
                        (user.failed_login_attempts ||
                          typeof user.failed_login_attempts != 'undefined' ||
                          user.failed_login_attempts != null) &&
                        user.failed_login_attempts > 3 &&
                        user.failed_login_attempts <= 5
                      "
                      variant="warning"
                      v-b-tooltip.hover.left.v-warning
                      title="Blocked due to more than 3 failed login attempts."
                      ><span class="align-middle"
                        ><feather-icon icon="InfoIcon" class="mr-25" /><span
                          >Temporarily Blocked</span
                        ></span
                      ></b-badge
                    >
                    <b-badge
                      v-else-if="
                        (user.failed_login_attempts ||
                          typeof user.failed_login_attempts != 'undefined' ||
                          user.failed_login_attempts != null) &&
                        user.failed_login_attempts > 5
                      "
                      variant="danger"
                      >Blocked</b-badge
                    >
                    <b-badge
                      v-else-if="
                        user.is_active == false &&
                        (!user.failed_login_attempts ||
                          typeof user.failed_login_attempts == 'undefined' ||
                          user.failed_login_attempts == null ||
                          user.failed_login_attempts <= 3)
                      "
                      variant="secondary"
                      >Not Verified</b-badge
                    >
                  </td>

                  <td
                    role="cell"
                    class="align-center flex-display text-align-right"
                  >
                    <div class="d-flex justify-content-center">
                      <b-dropdown size="sm" text="Select" variant="primary">
                        <b-dropdown-item @click.stop="editUser(user._id)"
                          ><span class="align-middle">
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span class="align-middle">Edit</span>
                          </span>
                        </b-dropdown-item>

                        <!-- <b-dropdown-item @click.stop="showModal(user._id, user)"
                          ><span class="align-middle">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span class="align-middle">Delete</span>
                          </span>
                        </b-dropdown-item> -->

                        <b-dropdown-item @click.stop="inviteUser(user._id)"
                          ><span class="align-middle">
                            <feather-icon icon="MailIcon" class="mr-50" />
                            <span class="align-middle">Invite</span>
                          </span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="
                            ((user.failed_login_attempts ||
                              typeof user.failed_login_attempts !=
                                'undefined' ||
                              user.failed_login_attempts != null) &&
                              user.failed_login_attempts > 3) ||
                            (typeof user.is_blocked != 'undefined' &&
                              user.is_blocked == true)
                          "
                          @click.stop="handleUnblockClick(user._id, user)"
                          ><span class="align-middle">
                            <feather-icon icon="SlashIcon" class="mr-50" />
                            <span class="align-middle">Unblock</span>
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <!-- <span
                      @click="UserDetails(user._id, user)"
                      class="icon-box-new-user pointer"
                    >
                      <feather-icon
                        class="delete-color rotate"
                        icon="EyeIcon"
                        size="20"
                      />
                    </span>
                    <span class="icon-box-new-user pointer">
                      <feather-icon
                        class="rotate"
                        icon="EditIcon"
                        size="20"
                        @click="editUser(user._id)"
                      />
                    </span>
                    <span
                      @click="showModal(user._id, user)"
                      class="icon-box-new-user pointer"
                    >
                      <feather-icon
                        class="delete-color rotate"
                        icon="Trash2Icon"
                        size="20"
                      />
                    </span>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="inviteUser(user._id)"
                    >
                      Invite
                    </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="card-body m-0 p-0">
              <b-modal
                id="modal-danger"
                ref="modal-danger"
                ok-only
                ok-variant="danger"
                @ok="deleteConsultant()"
                modal-class="modal-danger"
                centered
                title="Delete !"
                :ok-disabled="
                  !this.$store.state.auth.permissions.includes(
                    'consultant-delete'
                  )
                "
              >
                <b-card-text>
                  Are You Sure to Delete the {{ user_detail.firstname }}
                  {{ user_detail.lastname }} ?
                </b-card-text>
              </b-modal>
            </div>
          </div>
        </div>
        <div class="align-center-div">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="lastPage * perPage"
            class="float-right"
          />
        </div>
      </div>
    </b-card>
    <b-sidebar
      id="add-sub"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="addconsultant"
      lazy
    >
      <div class="p-2">
        <validation-observer ref="observer">
          <b-form-group label="Email" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="basicInput"
                placeholder="Email"
                v-model="emailid"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <template>
            <b-form-group label="First Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="First name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="First Name"
                  v-model="fname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Last name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Last Name"
                  v-model="lname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Phone Number" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Phone number"
                rules="required|integer"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Phone Number"
                  v-model="phonenumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="should_invite">
                Send invitation to mail
              </b-form-checkbox>
            </b-form-group>

            <button
              class="btn btn-primary mt-1 mr-1"
              @click.prevent="validationFormsub"
            >
              Add User
            </button>
          </template>

          <button class="btn btn-secondary mt-1" @click="closeAdduser()">
            Cancel
          </button>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-sidebar
      id="add-sub"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="editconsultant"
    >
      <div class="p-2">
        <validation-observer ref="observer">
          <b-form-group label="Email" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="basicInput"
                placeholder="Email"
                v-model="emailid"
                :disabled="true"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <template>
            <b-form-group label="First Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="First name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="First Name"
                  v-model="fname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Last name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Last Name"
                  v-model="lname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Phone Number" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Phone number"
                rules="required|integer"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Phone Number"
                  v-model="phonenumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <button
              class="btn btn-primary mt-1 mr-1"
              @click="updateUser"
              :disabled="
                !this.$store.state.auth.permissions.includes(
                  'consultant-update'
                )
              "
            >
              Update User
            </button>
          </template>

          <button class="btn btn-secondary mt-1" @click="closeEdituser()">
            Cancel
          </button>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-modal ref="modal-invite" title="Copy Invite " hide-footer>
      <div v-if="genLink">
        <input
          type="text"
          class="form-control mt-1"
          v-model="genLink"
          ref="invite-link"
        />

        <p class="text-success">
          Link Generated Successfully ! Please click below button to copy the
          link
        </p>
      </div>

      <button
        class="btn btn-primary btn-sm mt-1"
        @click="copylink()"
        :disabled="genLink == ''"
      >
        Copy Link
      </button>
    </b-modal>
  </div>
</template>
  
  <script>
import Vue from "vue";
import {
  BPagination,
  BModal,
  BCardText,
  BCard,
  BSidebar,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BBadge,
  BDropdown,
  BDropdownGroup,
  BDropdownDivider,
  BDropdownItem,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import ResponseMixins from "../../mixins/ResponseMixins";
import ConsultantMixins from "../../mixins/ConsultantMixins";
export default {
  data: () => ({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    rows: 1,
    allUsers: [],
    userId: "",
    searchResult: "",
    addconsultant: false,
    emailid: "",
    fname: "",
    lname: "",
    phonenumber: "",
    should_invite: "",
    required,
    email,
    editconsultant: false,
    consultantid: "",
    user_detail: [],
    inviteUserId: "",
    genLink: "",
  }),
  mixins: [ResponseMixins, ConsultantMixins],
  components: {
    BModal,
    BPagination,
    BCardText,
    BCard,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BBadge,
    BDropdown,
    BDropdownGroup,
    BDropdownDivider,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {},
  watch: {
    currentPage(value) {
      this.getUser(value);
    },
    searchResult() {
      this.searchResultData(this.currentPage);
    },
  },
  mounted() {
    this.getUser(this.currentPage);
  },
  computed: {},
  methods: {
    handleUnblockClick(id, user) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to unblock this User.", {
          title: "Warning",
          size: "sm",
          okVariant: "success",
          okTitle: "Unblock",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
          headerBgVariant: "light-warning",
        })
        .then((value) => {
          if (value) {
            const obj = {
              unblock_user: 1,
              firstname: user.firstname,
              lastname: user.lastname,
              email: user.email,
              mobile: user.mobile,
              user_type: "Customer",
            };
            this.updateConsultantUser(id, obj)
              .then((res) => {
                this.handleResponse(res);
              })
              .catch((err) => {
                console.log(err);
                this.handleError(err);
              })
              .finally(() => {
                this.getUser(this.currentPage);
              });
          }
        });
    },

    copylink() {
      const element = this.$refs["invite-link"];
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Link Copied !",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    inviteUser(userid) {
      this.inviteUserId = userid;

      this.generateInviteLink();
    },
    generateInviteLink() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/reinvite-link?user_id=${this.inviteUserId}`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.genLink = res.data.data.invite_link;
            this.$refs["modal-invite"].show();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    UserDetails(userid, user) {
      this.$router.push({
        name: "customeruserdetails",
        params: { id: userid },
      });
    },
    async closeAdduser() {
      this.addconsultant = false;
      this.emailid = "";
      this.fname = "";
      this.lname = "";
      this.phonenumber = "";
      this.$nextTick(() => this.$refs.observer.reset());
    },
    async closeEdituser() {
      this.editconsultant = false;
    },

    deleteConsultant() {
      const data = {
        user_id: this.userId,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/delete?user_id=${this.userId}`,
      };
      this.$http(options)
        .then((res) => {
          // this.getMembers(this.customerid);
          this.getUser(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.addusertotenant = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editUser(userid) {
      this.consultantid = userid;
      this.editconsultant = true;
      this.getUserDetails(this.consultantid);
    },
    getUserDetails(constant_id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL + `/user/show?user_id=${constant_id}`,
      };

      this.$http(options)
        .then((res) => {
          this.emailid = res.data.data.email;
          this.fname = res.data.data.firstname;
          this.lname = res.data.data.lastname;
          this.phonenumber = res.data.data.mobile;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateUser() {
      const data = {
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        user_type: "GRC_user",
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/update?user_id=${this.consultantid}`,
      };
      this.$http(options)
        .then((res) => {
          this.editconsultant = false;
          this.getUser(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.addusertotenant = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Consultant Updated",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchResultData(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_SSO_API_URL +
            `/user/get?search=${this.searchResult}&user_type=Customer`,
          params: { page: num, limit: this.perPage },
        };

        this.$http(options)
          .then((res) => {
            this.allUsers = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.perPage = res.data.data.per_page;
            this.rows = this.lastPage * 10;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addUser() {
      // this.$router.push({ name: "adduser" });
      this.addconsultant = true;
      this.emailid = "";
      this.fname = "";
      this.lname = "";
      this.phonenumber = "";
      this.$validator.reset();
    },
    validationFormsub() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.addConsultants();
        }
      });
    },
    async addConsultants() {
      const data = {
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        user_type: "GRC_user",
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user/add`,
      };
      this.$http(options)
        .then((res) => {
          this.addconsultant = false;

          this.getUser(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.addusertotenant = false;
            this.emailid = "";
            this.fname = "";
            this.lname = "";
            this.phonenumber = "";
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Consultant added",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.errors.email[0],
              icon: "EditIcon",
              variant: "success",
            },
          });
        });
    },
    viewDetail(id) {
      this.$router.push({ name: "customeruserdetails", params: { id: id } });
    },
    getUser(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_SSO_API_URL + `/user/get?user_type=Customer`,
          params: { page: num, limit: this.perPage },
        };
        this.$http(options)
          .then((res) => {
            this.allUsers = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.perPage = res.data.data.per_page;
            this.rows = this.lastPage * 10;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteUser(userid) {
      userid = this.userId;
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/users/${userid}`,
      };
      this.$http(options)
        .then((res) => {
          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showModal(itemid, user) {
      this.userId = itemid;
      this.user_detail = user;
      this.$refs["modal-danger"].show();
    },
  },
};
</script>
  
  <style>
.header {
  font-size: 20px;
  font-weight: 600;
}

.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.padding-20 {
  padding: 20px;
}
.transpent {
  background-color: rgba(130, 134, 139, 0.12) !important;
}
/* .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
  .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    background-color: #f3f2f7 !important;
    color: #6e6b7b;
  } */

.align-right {
  display: flex;
  justify-content: flex-end;
}
.user-select {
  user-select: none;
}
.icon-box-new-user {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}

.mr-bottom {
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}
.delete-color {
  color: #ea5455 !important;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}

.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}

.rowgroup tr:hover {
  cursor: pointer !important;
}

.text-align-right {
  text-align: right;
}

.modal .modal-header .close {
  position: relative;
  top: 4px;
  left: -10px;
}
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
  
  <style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>
