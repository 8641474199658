var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card p-2 bg-card"},[_c('div',{staticClass:"align-center-flex"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchResult),expression:"searchResult"}],staticClass:"mr-bottom form-control input-sm flex",attrs:{"placeholder":"Search"},domProps:{"value":(_vm.searchResult)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchResult=$event.target.value}}})]),_c('div')])]),_c('b-card',[_c('div',{staticClass:"user-select"},[_c('div',{staticClass:"card-company-table card card-congratulation-medal"},[_c('div',{staticClass:"mb-0 table-responsive border rounded",staticStyle:{"min-height":"40vh"}},[_c('table',{staticClass:"table table-hover",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup header"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader"}},[_vm._v("#")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("First Name")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Last Name")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Email")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Status")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader","scope":"col"}},[_vm._v(" Actions ")])])]),_c('tbody',{staticClass:"rowgroup"},_vm._l((_vm.allUsers),function(user,index){return _c('tr',{key:user._id,staticClass:"custom-table-row",attrs:{"role":"row"},on:{"click":function($event){return _vm.UserDetails(user._id, user)}}},[_c('td',{attrs:{"role":"cell"}},[_vm._v(" "+_vm._s((_vm.currentPage - 1) * _vm.perPage + index + 1)+" ")]),_c('td',{attrs:{"role":"cell"}},[_vm._v(_vm._s(user.firstname))]),_c('td',{attrs:{"role":"cell"}},[_vm._v(_vm._s(user.lastname))]),_c('td',{attrs:{"role":"cell"}},[_vm._v(_vm._s(user.email))]),_c('td',{attrs:{"role":"cell"}},[(
                      user.is_active &&
                      (!user.failed_login_attempts ||
                        typeof user.failed_login_attempts == 'undefined' ||
                        user.failed_login_attempts == null ||
                        user.failed_login_attempts <= 3) &&
                      (typeof user.is_blocked == 'undefined' ||
                        user.is_blocked == false)
                    )?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Active")]):(
                      typeof user.is_blocked != 'undefined' &&
                      user.is_blocked == true
                    )?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left.v-warning",modifiers:{"hover":true,"left":true,"v-warning":true}}],attrs:{"title":"Blocked due to Inactivity","variant":"warning"}},[_c('span',{staticClass:"align-middle"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"InfoIcon"}}),_c('span',[_vm._v("Temporarily Blocked")])],1)]):(
                      (user.failed_login_attempts ||
                        typeof user.failed_login_attempts != 'undefined' ||
                        user.failed_login_attempts != null) &&
                      user.failed_login_attempts > 3 &&
                      user.failed_login_attempts <= 5
                    )?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left.v-warning",modifiers:{"hover":true,"left":true,"v-warning":true}}],attrs:{"variant":"warning","title":"Blocked due to more than 3 failed login attempts."}},[_c('span',{staticClass:"align-middle"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"InfoIcon"}}),_c('span',[_vm._v("Temporarily Blocked")])],1)]):(
                      (user.failed_login_attempts ||
                        typeof user.failed_login_attempts != 'undefined' ||
                        user.failed_login_attempts != null) &&
                      user.failed_login_attempts > 5
                    )?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Blocked")]):(
                      user.is_active == false &&
                      (!user.failed_login_attempts ||
                        typeof user.failed_login_attempts == 'undefined' ||
                        user.failed_login_attempts == null ||
                        user.failed_login_attempts <= 3)
                    )?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Not Verified")]):_vm._e()],1),_c('td',{staticClass:"align-center flex-display text-align-right",attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-dropdown',{attrs:{"size":"sm","text":"Select","variant":"primary"}},[_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.editUser(user._id)}}},[_c('span',{staticClass:"align-middle"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Edit")])],1)]),_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.inviteUser(user._id)}}},[_c('span',{staticClass:"align-middle"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"MailIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Invite")])],1)]),(
                          ((user.failed_login_attempts ||
                            typeof user.failed_login_attempts !=
                              'undefined' ||
                            user.failed_login_attempts != null) &&
                            user.failed_login_attempts > 3) ||
                          (typeof user.is_blocked != 'undefined' &&
                            user.is_blocked == true)
                        )?_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.handleUnblockClick(user._id, user)}}},[_c('span',{staticClass:"align-middle"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SlashIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Unblock")])],1)]):_vm._e()],1)],1)])])}),0)]),_c('div',{staticClass:"card-body m-0 p-0"},[_c('b-modal',{ref:"modal-danger",attrs:{"id":"modal-danger","ok-only":"","ok-variant":"danger","modal-class":"modal-danger","centered":"","title":"Delete !","ok-disabled":!this.$store.state.auth.permissions.includes(
                  'consultant-delete'
                )},on:{"ok":function($event){return _vm.deleteConsultant()}}},[_c('b-card-text',[_vm._v(" Are You Sure to Delete the "+_vm._s(_vm.user_detail.firstname)+" "+_vm._s(_vm.user_detail.lastname)+" ? ")])],1)],1)])]),_c('div',{staticClass:"align-center-div"},[_c('b-pagination',{staticClass:"float-right",attrs:{"per-page":_vm.perPage,"hide-goto-end-buttons":"","total-rows":_vm.lastPage * _vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]),_c('b-sidebar',{attrs:{"id":"add-sub","bg-variant":"white","right":"","backdrop":"","shadow":"","width":"640px","lazy":""},model:{value:(_vm.addconsultant),callback:function ($$v) {_vm.addconsultant=$$v},expression:"addconsultant"}},[_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"observer"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"Email"},model:{value:(_vm.emailid),callback:function ($$v) {_vm.emailid=$$v},expression:"emailid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),[_c('b-form-group',{attrs:{"label":"First Name","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"First Name"},model:{value:(_vm.fname),callback:function ($$v) {_vm.fname=$$v},expression:"fname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"Last Name"},model:{value:(_vm.lname),callback:function ($$v) {_vm.lname=$$v},expression:"lname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"Phone Number"},model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=$$v},expression:"phonenumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.should_invite),callback:function ($$v) {_vm.should_invite=$$v},expression:"should_invite"}},[_vm._v(" Send invitation to mail ")])],1),_c('button',{staticClass:"btn btn-primary mt-1 mr-1",on:{"click":function($event){$event.preventDefault();return _vm.validationFormsub.apply(null, arguments)}}},[_vm._v(" Add User ")])],_c('button',{staticClass:"btn btn-secondary mt-1",on:{"click":function($event){return _vm.closeAdduser()}}},[_vm._v(" Cancel ")])],2)],1)]),_c('b-sidebar',{attrs:{"id":"add-sub","bg-variant":"white","right":"","backdrop":"","shadow":"","width":"640px"},model:{value:(_vm.editconsultant),callback:function ($$v) {_vm.editconsultant=$$v},expression:"editconsultant"}},[_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"observer"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"Email","disabled":true},model:{value:(_vm.emailid),callback:function ($$v) {_vm.emailid=$$v},expression:"emailid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),[_c('b-form-group',{attrs:{"label":"First Name","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"First Name"},model:{value:(_vm.fname),callback:function ($$v) {_vm.fname=$$v},expression:"fname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"Last Name"},model:{value:(_vm.lname),callback:function ($$v) {_vm.lname=$$v},expression:"lname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"Phone Number"},model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=$$v},expression:"phonenumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('button',{staticClass:"btn btn-primary mt-1 mr-1",attrs:{"disabled":!this.$store.state.auth.permissions.includes(
                'consultant-update'
              )},on:{"click":_vm.updateUser}},[_vm._v(" Update User ")])],_c('button',{staticClass:"btn btn-secondary mt-1",on:{"click":function($event){return _vm.closeEdituser()}}},[_vm._v(" Cancel ")])],2)],1)]),_c('b-modal',{ref:"modal-invite",attrs:{"title":"Copy Invite ","hide-footer":""}},[(_vm.genLink)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.genLink),expression:"genLink"}],ref:"invite-link",staticClass:"form-control mt-1",attrs:{"type":"text"},domProps:{"value":(_vm.genLink)},on:{"input":function($event){if($event.target.composing){ return; }_vm.genLink=$event.target.value}}}),_c('p',{staticClass:"text-success"},[_vm._v(" Link Generated Successfully ! Please click below button to copy the link ")])]):_vm._e(),_c('button',{staticClass:"btn btn-primary btn-sm mt-1",attrs:{"disabled":_vm.genLink == ''},on:{"click":function($event){return _vm.copylink()}}},[_vm._v(" Copy Link ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }