export default {
  methods: {
    updateConsultantUser(userId, obj) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          data: obj,
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_SSO_API_URL + `/user/update?user_id=${userId}`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
  },
};
